
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "ActionResultSelect",
  props: {
    result_id: {
      type: [Number, Array],
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    load: {
      type: Boolean,
      default: false,
    },
    all: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:result_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const actionsResultData = computed(() => store.getters.ActionResult);
    const inputAction = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isActionResultRequired = (value) => {
      if (props.required && !value) {
        return t("ractionresult");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "action_result",
      isActionResultRequired
    );

    const getActions = () => {
      if (props.load && !actionsResultData.value.length) {
        store.commit("setLoading", false);
        ApiService.get("/api/action_results").then(({ data }) => {
          store.commit("setActionResult", data);
          inputAction.value.list = data;
          inputAction.value.options = data;
        });
      } else {
        inputAction.value.list = actionsResultData.value;
        inputAction.value.options = actionsResultData.value;
      }
      const index = inputAction.value.options.findIndex(
        (x) => x.name === "Todos"
      );
      if (props.all) {
        if (index === -1) {
          inputAction.value.options.splice(0, 0, {
            id: 0,
            name: "Todos",
          });
        }
      } else if (index !== -1) {
        inputAction.value.options.splice(0, 1);
      }
    };
    const selectAction = (query) => {
      if (query !== "") {
        inputAction.value.loading = true;
        setTimeout(() => {
          inputAction.value.loading = false;
          inputAction.value.options = inputAction.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        inputAction.value.options = actionsResultData.value;
      }
    };

    watch(
      () => actionsResultData.value,
      (first) => {
        inputAction.value.list = first;
        inputAction.value.options = first;
      }
    );

    watch(
      () => props.result_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      getActions();
      setTimeout(function () {
        element_id.value = props.result_id;
      }, 500);
    });

    return {
      element_id,
      inputAction,
      errorMessage,
      selectAction,
    };
  },
};
